import { Card } from 'antd';

import { EditOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { CardSize } from 'antd/es/card/Card';

import style from './RenderCard.module.css';

const { Meta } = Card;

const RenderCard = (
  isNavigate: boolean,
  obj: any,
  key: string,
  titleCard: string,
  isActive: boolean,
  navigateTo: Function,
  EditItem: Function,
) => {
  const IsSize = () => {
    if (isNavigate) {
      return true;
    }
    return false;
  };

  return (
    <Card
      size={'small'}
      style={{ marginTop: '10px', backgroundColor: isActive ? '#C1D004' : '' }}
      className={style.actionsHeight}
      key={key}
      actions={[
        IsSize() && (
          <ArrowRightOutlined onClick={() => navigateTo(obj)} key="setting" />
        ),
        <EditOutlined onClick={() => EditItem(obj)} key="edit" />,
      ]}
    >
      <Meta title={titleCard} />
    </Card>
  );
};

export default RenderCard;
