import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import { TransputLogging } from '../../Models/Logging/TransputLogging';
import { serviceBundler, webSocketService } from '../../App';
import { LogoSensorState } from '../../Models/WebSocket/LogoSensorState';
import moment from 'moment';

import { MessageWebSocket } from '../../Models/WebSocket/MessageWebSocket';
import { MessageSock } from '../../Services/WebSocketService';
import { WebSocketMessageType } from '../../Enums/WebSocketMessageType';
import { WsTransputLoggings } from '../../Pages/PlatformManager/models/WsTransputLoggings';
import dayjs from 'dayjs';
import { timeStamp } from 'console';

const columns: ColumnsType<TransputLogging> = [
  {
    title: 'Module naam',
    dataIndex: 'moduleName',
  },
  {
    title: 'Sensor naam',
    dataIndex: 'sensorName',
  },
  {
    title: 'Sensor waarde',
    dataIndex: 'sensorValue',
    render: (text) => (text ? 'Ingeschakeld' : 'Uitgeschakeld'),
  },
  {
    title: 'Tijdstip',
    dataIndex: 'timeStamp',
    sorter: (a, b) => moment(a.timeStamp).unix() - moment(b.timeStamp).unix(),
  },
];

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export const TableLogs = forwardRef(
  (props: { platformId: string; exportData: Function }, ref) => {
    const [dataTemp, setTempData] = useState<Array<TransputLogging>>([]);
    const [data, setData] = useState<TransputLogging[]>();

    const [loading, setLoading] = useState(true);
    const [connectionReady, setConnectionReady] = useState(false);

    // const [tableParams, setTableParams] = useState<TableParams>({
    //   pagination: {
    //     current: 1,
    //     pageSize: 10,
    //   },
    // });

    useImperativeHandle(ref, () => ({
      SetSearchText(text: string) {
        if (text.length == 0) {
          setData([...dataTemp]);
          return;
        }

        var filteredData = dataTemp.filter((x) => {
          return (
            x.moduleName
              .toLocaleLowerCase()
              .includes(text.toLocaleLowerCase()) ||
            x.sensorName.toLocaleLowerCase().includes(text.toLocaleLowerCase())
          );
        });
        setData(filteredData);
      },

      ExportData() {
        props.exportData(data);
      },
    }));

    useEffect(() => {
      if (loading) {
        FetchData();
      }
      if (!loading && !connectionReady) {
        ConnectToWebsocket();
      }
    }, [data, loading, connectionReady]);

    const ConnectToWebsocket = () => {
      webSocketService.messagesChancel.subscribe((msg) => {
        const prodItem = Object.assign(new MessageWebSocket(), msg);

        HandleWebsocketMessages(prodItem, msg);
      });
      setConnectionReady(true);
    };

    const FetchData = async () => {
      var fetchedItems =
        await serviceBundler.transputLoggingService.GetTransputLoggings(
          props.platformId,
        );

      var items = fetchedItems.sort(
        (a: TransputLogging, b: TransputLogging) => {
          return (
            new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime()
          );
        },
      );
      setLoading(false);
      // var mappedItems = MapDates(items);
      setData(items);
      setTempData(items);
    };

    // const MapDates = (items: TransputLogging[]) => {
    //   items.map((item) => {
    //     item.timeStamp =
    //       item.timeStamp != ''
    //         ? dayjs(item.timeStamp).format('YYYY/MM/DD HH:mm:ss').toString()
    //         : '';
    //     return item;
    //   });
    //   return items;
    // };

    const handleTableChange = (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue>,
      sorter: SorterResult<TransputLogging>,
    ) => {
      // setTableParams({
      //   pagination,
      //   filters,
      //   ...sorter,
      // });
      // `dataSource` is useless since `pageSize` changed
      // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      //   setData([]);
      // }
    };

    const HandleWebsocketMessages = (
      prodItem: MessageWebSocket,
      msg: MessageSock,
    ) => {
      if (prodItem.Type == WebSocketMessageType.WsTransputLoggings) {
        var transputLoggings = Object.assign(new WsTransputLoggings(), msg);
        if (data) {
          var newArray = [...data, ...transputLoggings.ListItems];
          setData(newArray);
          setTempData(newArray);
        }
      }
    };

    return (
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        // pagination={tableParams.pagination}
        loading={loading}
      />
    );
  },
);
