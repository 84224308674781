
export class Module{
    id!: string

    idPlatform!: string;
    idUnit!: string;
    
    name!: string
    moduleId!: string
    enabled: boolean = false
    externalEnabled?: boolean = false
    isOnline?: boolean = false
    timeLastOnline?: Date
    styleIsOnline?: string
}
