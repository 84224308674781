import { AuthService } from './AuthService';
import { LanguageService } from './LanguageService';
import { PlatformService } from './PlatformService';
import { ThemeService } from './ThemeService';
import { TransputLoggingService } from './TransputLoggingService';
import { UserService } from './UserService';
import { WebSocketService } from './WebSocketService';

class ServiceBundler {
  public themeService;
  public languageService;
  public userService;
  public transputLoggingService;
  public platformService;

  constructor() {
    this.themeService = new ThemeService();

    this.languageService = new LanguageService();
    this.userService = new UserService();
    this.transputLoggingService = new TransputLoggingService();
    this.platformService = new PlatformService();
  }
}

export { ServiceBundler };
