import { TransputLogging } from "../Models/Logging/TransputLogging";
import { HttpHelper } from "./HttpHelper"
import { LocalHelper } from "./LocalHelper";

export abstract class TransputLoggingHelper{
    private static baseUrl = HttpHelper.GetUrlBaseApi() + "api/" + "TransputLogging"
    
    public static async GetTransputLoggings(platformId: string){
        var urlForRequest = this.baseUrl + "/" + platformId;
        return (await HttpHelper.GetRequestForApi<TransputLogging[]>(urlForRequest))
    }

    public static async ExportTransputLoggings(transputLoggings: TransputLogging[]){
        var urlForRequest = this.baseUrl + "/ExportToExcel";
        await HttpHelper.PostRequestForApiForFile(urlForRequest, transputLoggings)
    }
}