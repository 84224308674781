import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from '../Models/Language/Language';
import { LanguageHelper } from '../Helpers/LanguageHelper';
import { TransputLoggingHelper } from '../Helpers/TransputLoggingHelper';
import { TransputLogging } from '../Models/Logging/TransputLogging';

export class TransputLoggingService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public FetchingReady!: boolean;

  public transputsLogs!: TransputLogging[];

  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);
  }

  public async GetTransputLoggings(platformId: string) {
    if (this.transputsLogs){
      return this.transputsLogs;
    }
    this.transputsLogs = await TransputLoggingHelper.GetTransputLoggings(platformId);
    return this.transputsLogs;
  }

  public async ExportTransputLoggings(items: TransputLogging[]){
    await TransputLoggingHelper.ExportTransputLoggings(items);
  }
}
