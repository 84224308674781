export enum WebSocketMessageType {
  PotlijnNotificationItems,
  WsEspPotlijnCounterPressItem,
  WsEspPotlijnCounterTrayItem,
  WsEspSensorData,
  WsEspSensorsData,
  WsEspStatus,
  WsLogoSensorsData,
  WsLogosStatus,
  WsLogoOutputState,
  WsDeviceUpdate,
  WsEspControlPower,
  WsEspControlPowerResponce,
  WsEspControlReset,
  WsEspControlResetResponce,
  WsTransputLoggings,
  WsRequestTransputStates
}
