import { Button } from 'antd';
import { useEffect, useState } from 'react';

import { PlusCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import style from './StripeStyleModules.module.css';
import { Unit } from '../../../Models/PlatformManager/Unit';
import RenderCard from '../RenderCard';
import { AddUnitForm } from '../../../Pages/PlatformManager/forms/AddUnitForm/AddUnitForm';
import { serviceBundler } from '../../../App';

import { StylingModule } from '../../../Models/PlatformManager/StylingModule';
import { AddStylingModuleForm } from '../../../Pages/PlatformManager/forms/AddStylingModuleForm/AddStylingModuleForm';
import { Platform } from '../../../Models/PlatformManager/Platform';

const StripeStyleModules = (props: {
  unit: Unit;
  modules?: StylingModule[];
}) => {
  const [styleModule, setStyleModule] = useState<StylingModule>();
  const [styleModules, setStyleModules] = useState<Array<StylingModule>>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setStyleModules(props.modules);
  }, [props]);

  const FilterModules = (items: StylingModule[]) => {
    return items.filter((x) => x.idUnit == props.unit.id);
  };

  const AddUnit = () => {};

  const EditUnit = () => {};

  const NavContentUnit = (item: StylingModule) => {
    setStyleModule(item);
  };

  const IsItemActive = (item: StylingModule) => {
    if (styleModule?.name == item.name) {
      return true;
    }
    return false;
  };

  const handleCancelForm = () => {
    setIsModalOpen(false);
  };

  const OpenModelAddUnit = () => {
    setStyleModule(new StylingModule());
    setModelEditMode(false);
    setIsModalOpen(true);
  };

  const OpenModelEditUnit = (item: StylingModule) => {
    setStyleModule(item);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const SafeStylingModule = async (item: StylingModule) => {
    var results: StylingModule[] = new Array<StylingModule>();

    if (item.id !== undefined) {
      results = await serviceBundler.platformService.UpdateStylingModule(item);
    } else {
      if (serviceBundler.platformService.stylingModules == null) {
        serviceBundler.platformService.stylingModules = [item];
      } else {
        results = await serviceBundler.platformService.AddStylingModule(
          props.unit,
          item,
        );
      }
    }

    setStyleModules(
      FilterModules(
        results
      ),
    );

    setIsModalOpen(false);
  };

  const RemoveStylingModule = async (item: StylingModule) => {
    setStyleModule(undefined);
    setStyleModules(
      FilterModules(
        await serviceBundler.platformService.DeleteStylingModule(
          props.unit,
          item,
        ),
      ),
    );
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={style.scrollFor}>
        {styleModules?.map((item: StylingModule, i) =>
          RenderCard(
            false,
            item,
            item.id,
            item.name,
            IsItemActive(item),
            NavContentUnit,
            OpenModelEditUnit,
          ),
        )}
        <AddStylingModuleForm
          allData={styleModules}
          data={styleModule}
          open={isModalOpen}
          onEdit={isModelEditMode}
          onCancel={handleCancelForm}
          onSafe={SafeStylingModule}
          onRemove={RemoveStylingModule}
        ></AddStylingModuleForm>
        <Button
          onClick={OpenModelAddUnit}
          icon={<PlusCircleOutlined />}
          className={style.btnPos}
        >
          {t('addstylemodule')}
        </Button>
      </div>
    </>
  );
};

export default StripeStyleModules;
