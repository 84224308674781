import { BehaviorSubject, Observable } from 'rxjs';
import { Platform } from '../Models/PlatformManager/Platform';
import { PlatformHelper } from '../Helpers/PlatformHelper';
import { Unit } from '../Models/PlatformManager/Unit';
import { StylingModule } from '../Models/PlatformManager/StylingModule';
import { EspModule } from '../Models/PlatformManager/EspModule';
import { LogoModule } from '../Models/PlatformManager/LogoModule';
import { Transput } from '../Models/PlatformManager/Transput';

export class PlatformService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public FetchingReady!: boolean;

  public platform!: Platform;
  public platforms!: Platform[];

  public stylingModules!: StylingModule[];
  public espModules!: EspModule[];
  public logoModules!: LogoModule[];
  public transputs!: Transput[];

  private FetchingReadyStylings = false;
  private FetchingReadyEsps = false;
  private FetchingReadyLogos = false;
  private FetchingReadyTransputs = false;

  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);
  }

  private async FetchItems() {
    if (!this.FetchingReady) {
      this.FetchingReady = true;
      this.platforms = await PlatformHelper.GetPlatforms();
      this.FetchingReadySubject.next(true);
    }
  }

  public GetFetchStatus(): Observable<boolean> {
    this.FetchItems();
    return this.FetchingReadySubject;
  }

  // get overview
  public GetPlatforms() {
    return this.platforms;
  }

  public async GetSpecificPlaform(name: string): Promise<Platform> {
    if (this.platform) {
      if (this.platform.platformName == name) {
        return this.platform;
      } else {
        this.ResetData();
      }
    }
    var item = this.platforms.filter((x) => x.platformName == name)[0];
    var newItem = await PlatformHelper.GetSpecificPlaform(item.id);
    this.platform = Object.assign(new Platform(), newItem);
    return newItem;
  }

  public async GetCurrentPlaform(key: string): Promise<Platform> {
    this.platform = await PlatformHelper.GetSpecificPlaform(key);
    return this.platform;
  }

  public async SetCurrentPlaform(updatedPlatform: Platform) {
    this.platform = updatedPlatform;
  }

  public async GetStylingModules(): Promise<StylingModule[]> {
    if (this.stylingModules) {
      if (this.FetchingReadyStylings) {
        return this.stylingModules;
      }
    }
    this.stylingModules = await PlatformHelper.GetStylingModules(
      this.platform.id,
    );
    this.FetchingReadyStylings = true;
    return this.stylingModules;
  }

  public async GetEspModules(): Promise<EspModule[]> {
    if (this.espModules) {
      if (this.FetchingReadyEsps) {
        return this.espModules;
      }
    }
    this.espModules = await PlatformHelper.GetEspModules(this.platform.id);
    this.FetchingReadyEsps = true;
    return this.espModules;
  }

  public async GetLogoModules(): Promise<LogoModule[]> {
    if (this.logoModules) {
      if (this.FetchingReadyLogos) {
        return this.logoModules;
      }
    }
    this.logoModules = await PlatformHelper.GetLogoModules(this.platform.id);
    this.FetchingReadyLogos = true;
    return this.logoModules;
  }

  public async GetTransputs(): Promise<Transput[]> {
    if (this.transputs) {
      if (this.FetchingReadyTransputs) {
        return this.transputs;
      }
    }
    this.transputs = await PlatformHelper.GetTransputs(this.platform.id);
    this.FetchingReadyTransputs = true;
    return this.transputs;
  }

  public async UpdateUnit(item: Unit) {
    var index = this.platform.units.findIndex((x) => x.id == item.id);
    this.platform.units[index] = item;
    await this.UpdateSpecificPlatform(this.platform);
    return this.platform.units;
  }

  public async AddUnit(item: Unit) {
    if (this.platform.units) {
      this.platform.units.push(item);
    } else {
      this.platform.units = [item];
    }

    this.UpdateSpecificPlatform(this.platform);
    return this.platform.units;
  }

  public async AddStylingModule(unit: Unit, item: StylingModule) {
    item.idPlatform = this.platform.id;
    item.idUnit = unit.id;

    var createdItem = await PlatformHelper.AddStylingModule(unit, item);
    this.stylingModules.push(createdItem);

    if (unit.stylingModules == undefined) {
      unit.stylingModules = [];
    }
    unit.stylingModules.push(createdItem.id);
    var foundUnit = this.platform.units.filter((x) => x.id == unit.id)[0];
    foundUnit = unit;
    return this.stylingModules;
  }

  public async AddEspModule(unit: Unit, item: EspModule) {
    item.idPlatform = this.platform.id;
    item.idUnit = unit.id;

    var createdItem = await PlatformHelper.AddEspModule(unit, item);
    if (this.espModules == undefined) {
      this.espModules = [];
    }
    this.espModules.push(createdItem);

    if (unit.espModules == undefined) {
      unit.espModules = [];
    }
    unit.espModules.push(createdItem.id);
    var foundUnit = this.platform.units.filter((x) => x.id == unit.id)[0];
    foundUnit = unit;
    return this.espModules;
  }

  public async AddLogoModule(unit: Unit, item: LogoModule) {
    item.idPlatform = this.platform.id;
    item.idUnit = unit.id;

    var createdItem = await PlatformHelper.AddLogoModule(unit, item);
    if (this.logoModules == undefined) {
      this.logoModules = [];
    }
    this.logoModules.push(createdItem);

    if (unit.logoModules == undefined) {
      unit.logoModules = [];
    }
    unit.logoModules.push(createdItem.id);
    var foundUnit = this.platform.units.filter((x) => x.id == unit.id)[0];
    foundUnit = unit;
    return this.logoModules;
  }

  public async AddTransput(moduleId: string, item: Transput) {
    item.idPlatform = this.platform.id;
    item.idModule = moduleId;

    var createdItem = await PlatformHelper.AddTransput(moduleId, item);

    if (this.transputs == undefined) {
      this.transputs = [];
    }
    this.transputs.push(createdItem);

    var espModule = this.espModules.filter((x) => x.id == moduleId)[0];
    if (espModule) {
      if (!espModule?.transputs) {
        espModule.transputs = [];
      }
      espModule.transputs.push(createdItem.id);
    }

    var logoModule = this.logoModules.filter((x) => x.id == moduleId)[0];
    if (logoModule) {
      if (!logoModule?.transputs) {
        logoModule.transputs = [];
      }
      logoModule.transputs.push(createdItem.id);
    }

    return this.transputs;
  }

  public async UpdateBaseSpecificPlatform(item: Platform) {
    var index = this.platforms.findIndex((x) => x.id === item.id);
    this.platforms[index] = item;
    this.platform = item;
    return await PlatformHelper.UpdateBaseSpecificPlatform(item);
  }

  public async UpdateSpecificPlatform(item: Platform) {
    var index = this.platforms.findIndex((x) => x.id === item.id);
    this.platforms[index] = item;
    this.platform = item;
    return await PlatformHelper.UpdateSpecificPlatform(item);
  }

  public async UpdateStylingModule(item: StylingModule) {
    var index = this.stylingModules.findIndex((x) => x.id === item.id);
    this.stylingModules[index] = item;
    await PlatformHelper.UpdateStylingModule(item);
    return this.stylingModules;
  }

  public async UpdateEspModule(item: EspModule) {
    var index = this.espModules.findIndex((x) => x.id === item.id);
    this.espModules[index] = item;
    await PlatformHelper.UpdateEspModule(item);
    return this.espModules;
  }

  public async UpdateLogoModule(item: LogoModule) {
    var index = this.logoModules.findIndex((x) => x.id === item.id);
    this.logoModules[index] = item;
    await PlatformHelper.UpdateLogoModule(item);
    return this.logoModules;
  }

  public async UpdateTransput(item: Transput) {
    var index = this.transputs.findIndex((x) => x.id === item.id);
    this.transputs[index] = item;
    await PlatformHelper.UpdateTransput(item);
    return this.transputs;
  }

  public async AddPlatform(newItem: Platform) {
    var createdItem = await PlatformHelper.AddPlatform(newItem);
    this.platforms.push(createdItem);
  }

  public async DeletePlatform(item: Platform) {
    await PlatformHelper.DeletePlatform(item);

    var index = this.platforms.findIndex((x) => x.id === item.id);
    this.platforms.splice(index, 1);
    var indexDetail = this.platforms.findIndex((x) => x.id === item.id);
  }

  public async DeleteUnit(item: Unit) {
    var index = this.platform.units.findIndex((x) => x.id === item.id);
    this.platform.units.splice(index, 1);

    var updatedPlatform = await this.UpdateSpecificPlatform(this.platform);
  }

  public async DeleteStylingModule(item: Unit, stylingModule: StylingModule) {
    await PlatformHelper.DeleteStylingModule(item, stylingModule);

    var index = this.platforms.findIndex((x) => x.id === item.id);
    this.stylingModules.splice(index, 1);

    var unit = this.platform.units.filter((x) => x.id == item.id)[0];
    var indexStylingModule = unit.stylingModules.findIndex(
      (x) => x == stylingModule.id,
    );
    unit.stylingModules.slice(indexStylingModule, 1);
    return this.stylingModules;
  }

  public async DeleteEspModule(
    item: Unit,
    espModule: EspModule,
  ): Promise<EspModule[]> {
    await PlatformHelper.DeleteEspModule(item, espModule);

    var index = this.platforms.findIndex((x) => x.id === item.id);
    this.espModules.splice(index, 1);

    var unit = this.platform.units.filter((x) => x.id == item.id)[0];
    var indexModule = unit.espModules.findIndex((x) => x == espModule.id);
    unit.espModules.slice(indexModule, 1);
    return this.espModules;
  }

  public async DeleteLogoModule(
    item: Unit,
    logoModule: LogoModule,
  ): Promise<LogoModule[]> {
    await PlatformHelper.DeleteLogoModule(item, logoModule);

    var index = this.platforms.findIndex((x) => x.id === item.id);
    this.logoModules.splice(index, 1);

    var unit = this.platform.units.filter((x) => x.id == item.id)[0];
    var indexModule = unit.logoModules.findIndex((x) => x == logoModule.id);
    unit.logoModules.slice(indexModule, 1);
    return this.logoModules;
  }

  public async DeleteTransput(
    moduleId: string,
    transput: Transput,
  ): Promise<Transput[]> {
    PlatformHelper.DeleteTransput(moduleId, transput);

    var index = this.transputs.findIndex((x) => x.id === transput.id);
    this.transputs.splice(index, 1);

    return this.transputs;
  }

  private ResetData() {
    this.FetchingReadyStylings = false;
    this.FetchingReadyEsps = false;
    this.FetchingReadyLogos = false;
    this.FetchingReadyTransputs = false;
  }
}
