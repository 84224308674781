import { SubSystemType } from "./SubSystemType"

export class SubSystem{
    id!: string

    name!: string
    linkToRemoteApp !: string
    linkToLocalApp !: string

    type!: SubSystemType;
}