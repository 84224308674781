import { Button } from 'antd';
import { useEffect, useState } from 'react';

import { PlusCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import style from './StripeConfigUnit.module.css';
import { Unit } from '../../../Models/PlatformManager/Unit';
import RenderCard from '../RenderCard';
import { AddUnitForm } from '../../../Pages/PlatformManager/forms/AddUnitForm/AddUnitForm';
import { serviceBundler } from '../../../App';

import { v4 as uuidv4 } from 'uuid';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from '../SortableItem';
import { Platform } from '../../../Models/PlatformManager/Platform';

const StripeConfigUnit = (props: { platform: Platform, units: Unit[]; chooseUnit: Function }) => {
  const [unit, setUnit] = useState<Unit>();
  const [units, setUnits] = useState<Unit[]>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);

  const { t, i18n } = useTranslation();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    setUnits(props.units);
  }, [props]);

  const GetUnits = () => {
    if (units != undefined) {
      return units;
    }
    return [];
  };

  const NavContentUnit = (item: Unit) => {
    setUnit(item);
    props.chooseUnit(item);
  };

  const IsUnitActive = (item: Unit) => {
    if (unit?.name == item.name) {
      return true;
    }
    return false;
  };

  const handleCancelForm = () => {
    setIsModalOpen(false);
  };

  const OpenModelAddUnit = () => {
    setUnit(new Unit());
    setModelEditMode(false);
    setIsModalOpen(true);
  };

  const OpenModelEditUnit = (item: Unit) => {
    setUnit(item);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const SafeUnit = async (unit: Unit) => {
    var updatedUnits = new Array<Unit>();

    if (unit.id) {
      updatedUnits = await serviceBundler.platformService.UpdateUnit(unit);
    } else {
      unit.id = uuidv4();
      updatedUnits = await serviceBundler.platformService.AddUnit(unit);
    }

    setUnits(updatedUnits);
    setIsModalOpen(false);
  };

  const RemoveUnit = async (unit: Unit) => {
    await serviceBundler.platformService.DeleteUnit(unit);
    setUnit(undefined);
    setIsModalOpen(false);
  };

  const handleDragEnd = (event: any) => {
    let { active, over } = event;

    if (active.id !== over.id) {
      setUnits((items: any) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={GetUnits()}
          strategy={verticalListSortingStrategy}
        >
          <div className={style.scrollFor}>
            {GetUnits().map((item) =>
              RenderCard(
                true,
                item,
                item.id,
                item.name,
                IsUnitActive(item),
                NavContentUnit,
                OpenModelEditUnit,
              ),
            )}
          </div>
        </SortableContext>
      </DndContext>

      <AddUnitForm
        allData={units}
        data={unit}
        open={isModalOpen}
        onEdit={isModelEditMode}
        onCancel={handleCancelForm}
        onSafe={SafeUnit}
        onRemove={RemoveUnit}
      ></AddUnitForm>
      <Button
        onClick={OpenModelAddUnit}
        icon={<PlusCircleOutlined />}
        className={style.btnPos}
      >
        {t('addunit')}
      </Button>
    </>
  );
};

export default StripeConfigUnit;
