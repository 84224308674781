import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { serviceBundler, webSocketService } from '../../../App';
import { Platform } from '../../../Models/PlatformManager/Platform';
import {
  Button,
  Drawer,
  FloatButton,
  Layout,
  Popover,
  Space,
  Tooltip,
  theme,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';

import {
  ArrowLeftOutlined,
  EditOutlined,
  TableOutlined,
  WifiOutlined,
  ExportOutlined,
  QuestionCircleOutlined,
  BellOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';

import style from './PlaformManager.module.css';
import { Transput } from '../../../Models/PlatformManager/Transput';
import { StylingModule } from '../../../Models/PlatformManager/StylingModule';
import { MessageWebSocket } from '../../../Models/WebSocket/MessageWebSocket';
import { WebSocketMessageType } from '../../../Enums/WebSocketMessageType';
import { EspSensorState } from '../../../Models/WebSocket/EspSensorState';
import { LogosSensorStates } from '../../../Models/WebSocket/LogoSensorStates';
import { useTranslation } from 'react-i18next';
import { TableLogs } from '../../../Components/Table/TableLogs';
import { EspModule } from '../../../Models/PlatformManager/EspModule';
import { Module } from '../../../Models/PlatformManager/Module';
import { WsEspStatus } from '../models/WsEspStatus';
import { LogoModule } from '../../../Models/PlatformManager/LogoModule';
import { WsLogosStatus } from '../models/WsLogosStatus';

const { useToken } = theme;

import './../../../StyleSheets/platform-potlijn.css';
import './../../../StyleSheets/platform-steklijn.css';
import Search from 'antd/es/input/Search';
import { LocalHelper } from '../../../Helpers/LocalHelper';
import { EspSensorStates } from '../../../Models/WebSocket/EspSensorStates';
import { RequestTransputStates } from '../../../Models/WebSocket/RequestTransputStates';
import { TransputLogging } from '../../../Models/Logging/TransputLogging';
import { HttpHelper } from '../../../Helpers/HttpHelper';
import { SubSystem } from '../../../Models/PlatformManager/SubSystem';

const PlatformManager = () => {
  const { platformKey } = useParams();
  const navigate = useNavigate();
  const { token } = useToken();

  const { t, i18n } = useTranslation();

  const [showOnlyPlatform, setShowOnlyPlatform] = useState(false);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [connectionReady, setConnectionReady] = useState(false);
  const [platform, setPlatform] = useState<Platform>();

  const [styleModules, setStyleModules] = useState<StylingModule[]>();
  const [espModules, setEspModules] = useState<EspModule[]>();
  const [logoModules, setLogoModules] = useState<LogoModule[]>();
  const [transputs, setTransputs] = useState<Transput[]>();

  const childRef = useRef<any>();

  useEffect(() => {
    if (loading) {
      if (platformKey) {
        FetchData(platformKey);
      }
    }
    if (!loading && !connectionReady) {
      InitWebsocketConnection();
    }
  }, [loading, connectionReady]);

  const FetchData = (platformKey: string) => {
    serviceBundler.platformService.GetFetchStatus().subscribe(async (value) => {
      if (!value) {
        return;
      }

      if (loading) {
        var specificPlatform =
          await serviceBundler.platformService.GetSpecificPlaform(platformKey);

        if (specificPlatform === undefined) {
          navigate('/');
        }

        setPlatform(specificPlatform);

        var fetchedStylingModules =
          await serviceBundler.platformService.GetStylingModules();
        setStyleModules(fetchedStylingModules);

        var fetchedEspModules =
          await serviceBundler.platformService.GetEspModules();
        setEspModules(fetchedEspModules);

        var fetchedLogoModules =
          await serviceBundler.platformService.GetLogoModules();
        setLogoModules(fetchedLogoModules);

        var fetchedTransputs =
          await serviceBundler.platformService.GetTransputs();
        setTransputs(fetchedTransputs);

        setLoading(false);
      }
    });
  };

  const CheckDataForOnline = (item: Module): boolean => {
    if (!item.timeLastOnline) {
      item.timeLastOnline = new Date();
    }

    var date = new Date(item.timeLastOnline);
    date.setSeconds(date.getSeconds() + 20);

    if (new Date(date) > new Date()) {
      return true;
    }
    return false;
  };

  const InitWebsocketConnection = () => {
    webSocketService.ConnectToEndpoint(
      platform?.platformUrlForSocket ? platform?.platformUrlForSocket : '',
    );

    webSocketService.messages.subscribe((msg) => {
      HandleWebSocketMessage(Object.assign(new MessageWebSocket(), msg));
    });

    setConnectionReady(true);

    webSocketService.isOpen.subscribe((msg: boolean) => {
      if (msg) {
        RequestTransputStatesFromService();
      }
    });
  };

  const HandleWebSocketMessage = (message: MessageWebSocket) => {
    if (!transputs) {
      return;
    }

    if (message.Type == WebSocketMessageType.WsEspSensorsData) {
      var espStates = Object.assign(new EspSensorStates(), message);

      espStates.SensorStates.forEach((espState) => {
        var filteredEspTransputIndex = transputs.findIndex(
          (x) => x.sensorId == espState.SensorId,
        );

        if (filteredEspTransputIndex >= 0) {
          var newTransputs = [...transputs];
          newTransputs[filteredEspTransputIndex].portValue =
            espState.SensorValue;
          setTransputs(newTransputs);
          if (transputs == undefined) {
            return;
          }
        }
      });
    }

    if (message.Type == WebSocketMessageType.WsLogoSensorsData) {
      var logoStates = Object.assign(new LogosSensorStates(), message);

      logoStates.SensorStates.forEach((logoState) => {
        var filteredLogoTransputIndex = transputs.findIndex(
          (x) => x.sensorId == logoState.SensorId,
        );

        if (filteredLogoTransputIndex >= 0) {
          var newTransputs = [...transputs];
          newTransputs[filteredLogoTransputIndex].portValue =
            logoState.SensorValue;
          setTransputs(newTransputs);
        }
      });
    }

    if (message.Type == WebSocketMessageType.WsEspStatus) {
      if (!espModules) {
        return;
      }

      var espState: WsEspStatus = Object.assign(new WsEspStatus(), message);
      var newEspModules = [...espModules];
      var foundModule = newEspModules.find(
        (x) => x.moduleId == espState.ModuleId,
      );

      if (foundModule) {
        if (CheckDataForOnline(foundModule)) {
          foundModule.isOnline = true;
          setEspModules(newEspModules);
        }
      }
    }

    if (message.Type == WebSocketMessageType.WsLogosStatus) {
      if (!logoModules) {
        return;
      }
      var logoState: WsLogosStatus = Object.assign(
        new WsLogosStatus(),
        message,
      );

      var newLogoModules = [...logoModules];
      logoState.LogosStatus.forEach((item) => {
        var foundLogoModule = newLogoModules.find(
          (x) => x.moduleId == item.ModuleId,
        );
        if (foundLogoModule) {
          if (CheckDataForOnline(foundLogoModule)) {
            foundLogoModule.isOnline = true;
            setLogoModules(newLogoModules);
          }
        }
      });
    }
  };

  const RequestTransputStatesFromService = () => {
    webSocketService.sendMessage(new RequestTransputStates());
  };

  const BackToHome = () => {
    navigate('/');
  };

  const NavigateToSubSystem = (item: SubSystem) => {
    if (HttpHelper.IsRemoteHosted()) {
      window.location.href = item.linkToRemoteApp;
    } else {
      window.location.href = item.linkToLocalApp;
    }
  };

  const EditPlatform = () => {
    navigate(`/${platformKey}/config`, {
      state: {},
    });
  };

  const RenderStyleTransput = (item: Transput) => {
    if (!item.portValue) {
      return item.styleDefault;
    } else {
      return item.styleDefault + ' ' + item.styleInAction;
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const GetPlaformId = () => {
    return platform?.id ? platform.id : '';
  };

  const onSearch = (value: string) => {
    const node = childRef.current;
    node!.SetSearchText(value);
  };

  const onSearchType = (e: any) => {
    const node = childRef.current;
    node!.SetSearchText(e.target.value);
  };

  const ExportToExcel = async () => {
    const node = childRef.current;
    node!.ExportData();
  };

  const ExportToExcelCallBack = async (items: TransputLogging[]) => {
    await serviceBundler.transputLoggingService.ExportTransputLoggings(items);
  };

  const SliderChange = (value: any) => {
    console.log(value);
  };

  const GetSubSystems = () => {
    return platform?.subSystems ? platform?.subSystems : [];
  };

  return (
    <>
      {/* <link rel="stylesheet" type="text/css" href={platform?.linkToStyle} /> */}
      <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
        <Layout>
          <Header
            style={{ backgroundColor: token.colorPrimaryBg }}
            className={style.headerStyle}
          >
            {!showOnlyPlatform && (
              <div>
                <Button
                  onClick={BackToHome}
                  icon={<ArrowLeftOutlined />}
                  className={style.backBtn}
                ></Button>
                <div className={style.textName}>{platformKey}</div>
              </div>
            )}
          </Header>

          <Content
            style={{ backgroundColor: token.colorPrimaryBg }}
            className={style.contentBlock}
          >
            <div className={style.centerContent}>
              {!showOnlyPlatform && (
                <Popover
                  placement="right"
                  title={t('subsystems')}
                  style={{
                    backgroundColor: '#C1D200',
                  }}
                  content={
                    <>
                      {GetSubSystems().map((item: SubSystem, i) => (
                        <Button
                          icon={<BellOutlined />}
                          onClick={() => NavigateToSubSystem(item)}
                        >
                          {item.name}
                        </Button>
                      ))}

                      {/* <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: '#a0ad03',
                        },
                      }}
                    >
                      <Slider
                      onChange={SliderChange}
                        range
                        defaultValue={[20, 50, 80]}
                        style={{ width: '400px' }}
                      />
                    </ConfigProvider> */}
                    </>
                  }
                  trigger="hover"
                >
                  <FloatButton
                    shape="square"
                    icon={<AppstoreOutlined />}
                    style={{
                      left: 30,
                      top: window.innerHeight / 2 - 125,
                      width: 40,
                      height: 200,
                    }}
                  />
                </Popover>
              )}

              {styleModules?.map((item) => (
                // <Popover
                //   overlayStyle={{
                //     width: '150px',
                //   }}
                //   content={
                //     <>
                //       <div style={{ display: 'flex' }}>
                //         <Tooltip title={'showTable'} placement={'bottom'}>
                //           <Button
                //             style={{ margin: 'auto' }}
                //             icon={<TableOutlined />}
                //             type="default"
                //           ></Button>
                //         </Tooltip>
                //         <Tooltip title={'showSettings'} placement={'bottom'}>
                //           <Button
                //             style={{ margin: 'auto' }}
                //             icon={<EditOutlined />}
                //             type="default"
                //           ></Button>
                //         </Tooltip>
                //       </div>
                //     </>
                //   }
                //   title={item.name}
                // >
                <Tooltip title={item.name}>
                  <div className={item.styleDefault}></div>
                </Tooltip>
                // </Popover>
              ))}

              {espModules?.map((item) => (
                <Tooltip title={item.name}>
                  <div className={item.styleIsOnline}>
                    {!item.isOnline && (
                      <div className={style.diagonalLine}></div>
                    )}
                    <WifiOutlined />
                  </div>
                </Tooltip>
              ))}

              {logoModules?.map((item) => (
                <Tooltip title={item.name}>
                  <div className={item.styleIsOnline}>
                    {!item.isOnline && (
                      <div className={style.diagonalLine}></div>
                    )}
                    <WifiOutlined />
                  </div>
                </Tooltip>
              ))}

              {transputs?.map((item) => (
                <Tooltip title={item.name}>
                  <div className={RenderStyleTransput(item)}></div>
                </Tooltip>
              ))}
            </div>
          </Content>

          <Drawer
            width={600}
            title={t('logs')}
            placement="right"
            closable={true}
            onClose={onClose}
            open={open}
            extra={
              <>
                <Button
                  onClick={ExportToExcel}
                  style={{ marginRight: 10 }}
                  icon={<ExportOutlined />}
                >
                  {t('export')}
                </Button>

                <Search
                  placeholder={t('inputsearch').toString()}
                  allowClear
                  onChange={onSearchType}
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </>
            }
          >
            <TableLogs
              ref={childRef}
              platformId={GetPlaformId()}
              exportData={ExportToExcelCallBack}
            ></TableLogs>
          </Drawer>

          {!showOnlyPlatform && (
            <>
              <FloatButton.Group shape="circle" style={{ right: 24 }}>
                <Popover
                  placement="left"
                  title={t('connectionproblems')}
                  content={
                    <Button onClick={() => window.location.reload()}>
                      {t('clicktoreload')}
                    </Button>
                  }
                  trigger="click"
                >
                  <FloatButton icon={<QuestionCircleOutlined />} />
                </Popover>

                <FloatButton
                  onClick={() => EditPlatform()}
                  type="primary"
                  icon={<EditOutlined />}
                />
              </FloatButton.Group>
              <FloatButton
                onClick={() => showDrawer()}
                icon={<TableOutlined />}
                style={{ right: 70 + 10 }}
              />
            </>
          )}
        </Layout>
      </Space>
    </>
  );
};

export default PlatformManager;
