import { PlatformType } from "../../Enums/PlatformType";
import { SubSystem } from "./SubSystem";
import { Unit } from "./Unit"

export class Platform{
    id!: string;
    
    platformName!: string;
    platformType!: PlatformType;
    description!: string;
    linkToImage!: string;
    pathToImage!: string;
    linkToStyle!: string;
    platformUrlForSocket!: string
    subSystems!: SubSystem[];
    units!: Unit[]
}