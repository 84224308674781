import { Button, Form, Input, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Platform } from '../../../../Models/PlatformManager/Platform';
import { RemoveButton } from '../../../../Components/Buttons/RemoveButton';

import { SubSystemType } from '../../../../Models/PlatformManager/SubSystemType';
import { SubSystem } from '../../../../Models/PlatformManager/SubSystem';

import { v4 as uuidv4 } from 'uuid';

export function AddSubSystem(props: {
  allData?: SubSystem[];
  data?: SubSystem;
  open: boolean;
  onCancel: Function;
  onSafe: Function;
  onRemove: Function;
  onEdit: boolean;
}) {
  const [form] = Form.useForm();

  const { i18n, t } = useTranslation();

  const [removeItem, setRemoveItem] = useState(false);
  const [subSystem, setSubSystem] = useState<SubSystem>();

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    form.resetFields();
    setSubSystem(props.data);
    setRemoveItem(false);
  }, [form, props.data, subSystem]);

  const handleCancel = () => {
    props.onCancel();
  };

  const onFinishForm = (values: SubSystem) => {
    if (props.data?.id !== undefined) {
      values.id = props.data?.id;
    } else {
      values.id = uuidv4();
    }

    if (removeItem) {
      props.onRemove(values);
    } else {
      props.onSafe(values);
    }
  };

  const onFinishFormFailed = (errorInfo: any) => {};

  const checkLabelExist = (_: any, value: string) => {
    var foundItem = props.allData?.find((x) => x.name === value);
    if (foundItem !== undefined) {
      if (foundItem.id === props.data?.id) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t('namealreadyexists').toString()));
    }
    return Promise.resolve();
  };

  const RenderRemoveButton = () => {
    var doRemove = false;

    if (subSystem?.id) {
      return (
        <RemoveButton
          disabled={doRemove}
          textBtn={t('remove')}
          bindedFunction={setRemoveItem}
          bindedForm={form}
        ></RemoveButton>
      );
    }
    return <></>;
  };

  const GetSubSytemType = () => {
    return [
      { value: SubSystemType.NotificationSystem, label: 'Notificatiesysteem' },
    ];
  };

  return (
    <Modal
      title={t('addsubsystem')}
      open={props.open}
      onOk={() => form.submit()}
      okText={t('safe')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('cancel')}
        </Button>,
        <>
          {RenderRemoveButton()}

          <Button key="submit" type="primary" onClick={() => form.submit()}>
            {t('safe')}
          </Button>
        </>,
      ]}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={props.data}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFormFailed}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label={t('name')}
          name="name"
          key="name"
          required
          rules={[
            {
              required: true,
              message: t('namrequired').toString(),
            },
            { validator: checkLabelExist },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('linktoremoteapp')}
          name="linkToRemoteApp"
          key="linkToRemoteApp"
          required
          rules={[
            {
              required: true,
              message: t('namrequired').toString(),
            },
            { validator: checkLabelExist },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('linktolocalapp')}
          name="linkToLocalApp"
          key="linkToLocalApp"
          required
          rules={[
            {
              required: true,
              message: t('namrequired').toString(),
            },
            { validator: checkLabelExist },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Type')}
          name="type"
          key="type"
          rules={[{ required: true, message: 'Selecteer een type' }]}
        >
          <Select options={GetSubSytemType()}>
            <Select.Option value="demo">Demo</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
