import { WebSocketMessageType } from '../../../Enums/WebSocketMessageType';
import { TransputLogging } from '../../../Models/Logging/TransputLogging';

export class WsTransputLoggings {
  Type: WebSocketMessageType;
  ListItems: Array<TransputLogging> = new Array<TransputLogging>();

  constructor() {
    this.Type = WebSocketMessageType.WsTransputLoggings;
  }
}
