import { Button } from 'antd';
import { useEffect, useState } from 'react';

import { PlusCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import style from './StripeLogoModules.module.css';
import { Unit } from '../../../Models/PlatformManager/Unit';
import RenderCard from '../RenderCard';
import { serviceBundler } from '../../../App';

import { LogoModule } from '../../../Models/PlatformManager/LogoModule';
import { AddLogoModuleForm } from '../../../Pages/PlatformManager/forms/AddLogoModuleForm/AddLogoModuleForm';

const StripeLogoModules = (props: {
  unit: Unit;
  setModule: Function;
  modules?: LogoModule[];
}) => {
  const [logoModule, setLogoModule] = useState<LogoModule>();
  const [logoModules, setLogoModules] = useState<Array<LogoModule>>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLogoModules(props.modules);
  }, [props]);

  const FilterModules = (items: LogoModule[]) => {
    return items.filter((x) => x.idUnit == props.unit.id);
  };

  const NavContentUnit = (item: LogoModule) => {
    setLogoModule(item);
    props.setModule(item.id);
  };

  const IsItemActive = (item: LogoModule) => {
    if (logoModule?.name == item.name) {
      return true;
    }
    return false;
  };

  const handleCancelForm = () => {
    setIsModalOpen(false);
  };

  const OpenModelAddUnit = () => {
    props.setModule(undefined);
    setLogoModule(new LogoModule());
    setModelEditMode(false);
    setIsModalOpen(true);
  };

  const OpenModelEditUnit = (item: LogoModule) => {
    setLogoModule(item);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const SafeLogoModule = async (item: LogoModule) => {
    setIsModalOpen(false);

    var result = Array<LogoModule>();
    if (item.id !== undefined) {
      result = await serviceBundler.platformService.UpdateLogoModule(item);
    } else {
      result = await serviceBundler.platformService.AddLogoModule(
        props.unit,
        item,
      );
    }

    setLogoModules(FilterModules(result));
  };

  const RemoveLogoModule = async (item: LogoModule) => {
    setIsModalOpen(false);

    setLogoModule(undefined);
    setLogoModules(
      FilterModules(
        await serviceBundler.platformService.DeleteLogoModule(props.unit, item),
      ),
    );
  };

  return (
    <>
      <div className={style.scrollFor}>
        {logoModules?.map((item: LogoModule, i) =>
          RenderCard(
            true,
            item,
            item.id,
            item.name,
            IsItemActive(item),
            NavContentUnit,
            OpenModelEditUnit,
          ),
        )}
      </div>

      <AddLogoModuleForm
        allData={logoModules}
        data={logoModule}
        open={isModalOpen}
        onEdit={isModelEditMode}
        onCancel={handleCancelForm}
        onSafe={SafeLogoModule}
        onRemove={RemoveLogoModule}
      ></AddLogoModuleForm>
      <Button
        onClick={OpenModelAddUnit}
        icon={<PlusCircleOutlined />}
        className={style.btnPos}
      >
        {t('addlogomodule')}
      </Button>
    </>
  );
};

export default StripeLogoModules;
