import { WebSocketMessageType } from "../../../Enums/WebSocketMessageType";
import { WsLogoStatus } from "./WsLogoStatus";

export class WsLogosStatus {
  Type: WebSocketMessageType;
  LogosStatus: Array<WsLogoStatus> = new Array<WsLogoStatus>();

  constructor() {
    this.Type = WebSocketMessageType.WsLogosStatus;
  }
}
