import {
  Button,
  Form,
  Input,
  List,
  Modal,
  Select,
  Tooltip,
  Typography,
  Upload,
  message,
} from 'antd';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Platform } from '../../../../Models/PlatformManager/Platform';
import { RemoveButton } from '../../../../Components/Buttons/RemoveButton';

import {
  LoadingOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import style from './AddPlatform.module.css';

import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { PlatformHelper } from '../../../../Helpers/PlatformHelper';
import { PlatformType } from '../../../../Enums/PlatformType';
import { AddSubSystem } from '../AddSubSystem/AddSubSystem';
import { SubSystem } from '../../../../Models/PlatformManager/SubSystem';
import { serviceBundler } from '../../../../App';

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export function AddPlatform(props: {
  allData?: Platform[];
  data?: Platform;
  open: boolean;
  onCancel: Function;
  onSafe: Function;
  onSafeImage: Function;
  onRemove: Function;
  onEdit: boolean;
  actionLoading: boolean;
}) {
  const [form] = Form.useForm();

  const { i18n, t } = useTranslation();

  const [removeItem, setRemoveItem] = useState(false);
  const [platform, SetPlatform] = useState<Platform>();
  const [subSystem, setSubSystem] = useState<SubSystem>();

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);

  useEffect(() => {
    form.resetFields();
    SetPlatform(props.data);
    setRemoveItem(false);
    setImageUrl(props.data?.linkToImage);
  }, [form, props.data, platform]);

  const handleCancel = () => {
    props.onCancel();
  };

  const onFinishForm = (values: Platform) => {
    if (props.data?.id !== undefined) {
      values.id = props.data?.id;
    }
    if (props.data?.pathToImage !== undefined) {
      values.pathToImage = props.data?.pathToImage;
    }
    if (props.data?.linkToImage !== undefined) {
      values.linkToImage = props.data?.linkToImage;
    }
    if (props.data?.subSystems !== undefined) {
      values.subSystems = props.data?.subSystems;
    }

    if (removeItem) {
      props.onRemove(values);
    } else {
      props.onSafe(values);
    }
  };

  const onFinishFormFailed = (errorInfo: any) => {};

  const checkLabelExist = (_: any, value: string) => {
    var foundItem = props.allData?.find((x) => x.platformName === value);
    if (foundItem !== undefined) {
      if (foundItem.id === props.data?.id) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t('namealreadyexist').toString()));
    }
    return Promise.resolve();
  };

  const OpenModelAddSubSystem = () => {
    setSubSystem(new SubSystem());
    setModelEditMode(false);
    setIsModalOpen(true);
  };

  const OpenModelEditSubSystem = (item: SubSystem) => {
    setSubSystem(item);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const SafeSybSystem = async (item: SubSystem) => {
    if (!platform) {
      return;
    }

    if (platform?.subSystems == null) {
      platform.subSystems = [item];
    }

    var indexSubSystem = platform?.subSystems.findIndex((x) => x.id == item.id);

    if (indexSubSystem != undefined) {
      if (indexSubSystem >= 0) {
        platform.subSystems[indexSubSystem] = item;
      } else {
        platform?.subSystems.push(item);
      }
    }

    SetPlatform(platform);
    setIsModalOpen(false);
  };

  const RemoveSybSystem = async (props: Platform) => {
    await serviceBundler.platformService.DeletePlatform(props);
    setIsModalOpen(false);
  };

  const handleCancelForm = () => {
    setIsModalOpen(false);
  };

  const RenderRemoveButton = () => {
    var doRemove = false;
    if (platform?.units) {
      doRemove = platform?.units.length > 0 ? true : false;
    }

    if (platform?.id) {
      return (
        <>
          <RemoveButton
            disabled={doRemove}
            textBtn={t('remove')}
            bindedFunction={setRemoveItem}
            bindedForm={form}
          ></RemoveButton>
          {doRemove && (
            <Tooltip title={t('removeconnectedunitsbeforeremove')}>
              <InfoCircleOutlined style={{ marginLeft: 10, marginRight: 10 }} />
            </Tooltip>
          )}
        </>
      );
    }
    return <></>;
  };

  const GetPlatformType = () => {
    return [
      { value: PlatformType.PotLine, label: 'Potlijn' },
      { value: PlatformType.CuttingLine, label: 'Steklijn' },
      { value: PlatformType.SortingLine, label: 'Sorteerlijn' },
      { value: PlatformType.Irrigation, label: 'Beregening' },
    ];
  };

  const GetUploadUrl = (): string => {
    return PlatformHelper.GetLinkAddImagePlatform(
      platform?.id ? platform.id : '',
    );
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      getBase64(file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
        file.preview = url;
      });
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    );
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>,
  ) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
        props.onSafeImage(url);
      });
    }
  };

  const GetSubSystems = () => {
    return platform?.subSystems ? platform?.subSystems : [];
  };

  const GetMarginModel = () => {
    if (window.outerHeight >= 700) {
      return 40;
    } else {
      80;
    }
  };

  return (
    <Modal
      style={{ top: GetMarginModel() }}
      title={t('addplatform')}
      open={props.open}
      onOk={() => form.submit()}
      okText={t('safe')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('cancel')}
        </Button>,
        <>
          {RenderRemoveButton()}

          <Button
            key="submit"
            type="primary"
            loading={props.actionLoading}
            onClick={() => form.submit()}
          >
            {t('safe')}
          </Button>
        </>,
      ]}
    >
      <>
        <AddSubSystem
          allData={platform?.subSystems}
          data={subSystem}
          open={isModalOpen}
          onCancel={handleCancelForm}
          onSafe={SafeSybSystem}
          onRemove={RemoveSybSystem}
          onEdit={isModelEditMode}
        ></AddSubSystem>
      </>

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={props.data}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFormFailed}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label={t('platformname')}
          name="platformName"
          key="platformName"
          required
          rules={[
            {
              required: true,
              message: t('platformnamrequired').toString(),
            },
            { validator: checkLabelExist },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('socketkey')}
          name="platformUrlForSocket"
          key="platformUrlForSocket"
          rules={[{ required: true, message: t('fillsocketkey').toString() }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('description')}
          name="description"
          key="description"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('linktostyle')}
          name="linkToStyle"
          key="linkToStyle"
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('subsystems')} key="subsystems">
          <List
            dataSource={GetSubSystems()}
            bordered
            renderItem={(item) => (
              <List.Item>
                <Typography.Text
                  className={style.hoverSubSystenName}
                  onClick={() => OpenModelEditSubSystem(item)}
                >
                  {item.name}
                </Typography.Text>
              </List.Item>
            )}
          ></List>
          <Button
            style={{ marginTop: 10 }}
            onClick={() => OpenModelAddSubSystem()}
            icon={<PlusOutlined />}
          >
            {t('addsubsystem')}
          </Button>
        </Form.Item>
        <Form.Item
          label={t('platformtype')}
          name="platformType"
          key="platformType"
          rules={[{ required: true, message: 'Selecteer een type' }]}
        >
          <Select options={GetPlatformType()}>
            <Select.Option value="demo">Demo</Select.Option>
          </Select>
        </Form.Item>
        {platform?.id != null && (
          <Form.Item label={t('image')} name="image" key="image">
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={GetUploadUrl()}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              onPreview={handlePreview}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}
