import {
  Button,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';

import style from './AddUser.module.css';
import Link from 'antd/es/typography/Link';
import { useTranslation } from 'react-i18next';
import { Platform } from '../../../../Models/PlatformManager/Platform';
import { StylingModule } from '../../../../Models/PlatformManager/StylingModule';
import { RemoveButton } from '../../../../Components/Buttons/RemoveButton';

export function AddStylingModuleForm(props: {
  allData?: StylingModule[];
  data?: StylingModule;
  open: boolean;
  onCancel: Function;
  onSafe: Function;
  onRemove: Function;
  onEdit: boolean;
}) {
  const [form] = Form.useForm();

  const { i18n, t } = useTranslation();

  const [removeItem, setRemoveItem] = useState(false);
  const [stylingModule, setStylingModule] = useState<StylingModule>();

  useEffect(() => {
    form.resetFields();
    setStylingModule(props.data);
    setRemoveItem(false);
  }, [form, props.data, stylingModule]);

  const handleCancel = () => {
    props.onCancel();
  };

  const onFinishForm = (values: StylingModule) => {
    if (props.data?.id !== undefined) {
      values.id = props.data?.id;
    }
    if (props.data?.idPlatform !== undefined) {
      values.idPlatform = props.data?.idPlatform;
    }
    if (props.data?.idUnit !== undefined) {
      values.idUnit = props.data?.idUnit;
    }

    if (removeItem) {
      props.onRemove(values);
    } else {
      props.onSafe(values);
    }
  };

  const onFinishFormFailed = (errorInfo: any) => {};

  const checkLabelExist = (_: any, value: string) => {
    var foundItem = props.allData?.find((x) => x.name === value);
    if (foundItem !== undefined) {
      if (foundItem.id === props.data?.id) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Deze naam bestaat al'));
    }
    return Promise.resolve();
  };

  const RenderRemoveButton = () => {
    if (stylingModule?.id) {
      return (
        <RemoveButton
          disabled={false}
          textBtn={t('remove')}
          bindedFunction={setRemoveItem}
          bindedForm={form}
        ></RemoveButton>
      );
    }
    return <></>;
  };

  return (
    <Modal
      title={t('addstylingmodule')}
      open={props.open}
      onOk={() => form.submit()}
      okText={t('safe')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('cancel')}
        </Button>,
        <>
          {RenderRemoveButton()}

          <Button key="submit" type="primary" onClick={() => form.submit()}>
            {t('safe')}
          </Button>
        </>,
      ]}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={props.data}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFormFailed}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label={t('name')}
          name="name"
          key="name"
          required
          rules={[
            {
              required: true,
              message: t('platformnamrequired').toString(),
            },
            { validator: checkLabelExist },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('styleDefault')}
          name="styleDefault"
          key="styleDefault"
          rules={[{ required: true, message: t('fillsocketkey').toString() }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
