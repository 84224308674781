import { EspSensorState } from './EspSensorState';

export class EspSensorStates {
  Type?: string;
  SessionId?: string;

  SensorStates: Array<EspSensorState> = [];

  constructor() {}
}
