import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Switch,
  Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';

import { BarcodeOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { Transput } from '../../../../Models/PlatformManager/Transput';
import { TransputType } from '../../../../Enums/TransputType';
import { ModuleType } from '../../../../Enums/ModuleType';
import { RemoveButton } from '../../../../Components/Buttons/RemoveButton';

import { v4 as uuidv4 } from 'uuid';

export function AddTransputForm(props: {
  allData?: Transput[];
  data?: Transput;
  open: boolean;
  onCancel: Function;
  onSafe: Function;
  onRemove: Function;
  onEdit: boolean;
}) {
  const [form] = Form.useForm();

  const { i18n, t } = useTranslation();

  const [removeItem, setRemoveItem] = useState(false);
  const [transput, setTransput] = useState<Transput>();

  useEffect(() => {
    form.resetFields();
    setTransput(props.data);
    setRemoveItem(false);
  }, [form, props.data, transput]);

  const handleCancel = () => {
    props.onCancel();
  };

  const onFinishForm = (values: Transput) => {
    if (props.data?.id !== undefined) {
      values.id = props.data?.id;
    }
    if (props.data?.idPlatform !== undefined) {
      values.idPlatform = props.data?.idPlatform;
    }
    if (props.data?.idModule !== undefined) {
      values.idModule = props.data?.idModule;
    }

    if (removeItem) {
      props.onRemove(values);
    } else {
      props.onSafe(values);
    }
  };

  const onFinishFormFailed = (errorInfo: any) => {};

  const checkLabelExist = (_: any, value: string) => {
    var foundItem = props.allData?.find((x) => x.name === value);
    if (foundItem !== undefined) {
      if (foundItem.id === props.data?.id) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Deze naam bestaat al'));
    }
    return Promise.resolve();
  };

  const checkSensorIdExist = (_: any, value: string) => {
    var foundItem = props.allData?.find((x) => x.sensorId === value);
    if (foundItem !== undefined) {
      if (foundItem.sensorId === props.data?.sensorId) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Deze naam bestaat al'));
    }
    return Promise.resolve();
  };

  const RenderRemoveButton = () => {
    if (transput?.id) {
      return (
        <RemoveButton
          disabled={false}
          textBtn={t('remove')}
          bindedFunction={setRemoveItem}
          bindedForm={form}
        ></RemoveButton>
      );
    }
    return <></>;
  };

  const GetModuleType = () => {
    return [
      { value: ModuleType.EspTransput, label: 'Esp' },
      { value: ModuleType.LogoTransout, label: 'Logo' },
    ];
  };

  const GetTransputType = () => {
    return [
      { value: TransputType.Input, label: 'Input' },
      { value: TransputType.Output, label: 'Output' },
      { value: TransputType.Marker, label: 'Marker' },
      { value: TransputType.Nothing, label: '-' },
    ];
  };

  const GetMaxInut = (): number => {
    if (transput){
      if (transput.moduleType == ModuleType.EspTransput){
        return 35;
      }
      else{ 
        return 25;
      }
    }
    return 1;
  }

  return (
    <Modal
      style={{ top: 20 }}
      title={t('addtransput')}
      open={props.open}
      onOk={() => form.submit()}
      okText={t('safe')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('cancel')}
        </Button>,
        <>
          {RenderRemoveButton()}

          <Button key="submit" type="primary" onClick={() => form.submit()}>
            {t('safe')}
          </Button>
        </>,
      ]}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={props.data}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFormFailed}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label={t('name')}
          name="name"
          key="name"
          required
          rules={[
            {
              required: true,
              message: t('fillfield').toString(),
            },
            { validator: checkLabelExist },
          ]}
        >
          <Input />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Form.Item
            style={{ width: 410 }}
            label={t('sensorId')}
            name="sensorId"
            key="sensorId"
            rules={[
              { required: true, message: t('fillfield').toString() },
              { validator: checkSensorIdExist },
            ]}
          >
            <Input />
          </Form.Item>
          <Tooltip title={t('generatecode')}>
            <Button
              onClick={() => form.setFieldValue('sensorId', uuidv4())}
              style={{ marginLeft: 10 }}
              icon={<BarcodeOutlined />}
            ></Button>
          </Tooltip>
        </div>
        <Form.Item
          label={t('description')}
          name="description"
          key="description"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('moduleType')}
          name="moduleType"
          key="moduleType"
          rules={[{ required: true, message: 'Selecteer een type' }]}
        >
          <Select disabled={true} options={GetModuleType()}>
            <Select.Option value="demo">Demo</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('transputType')}
          name="transputType"
          key="transputType"
          rules={[{ required: true, message: 'Selecteer een type' }]}
        >
          <Select options={GetTransputType()}>
            <Select.Option value="demo">Demo</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('portNumber')}
          name="portNumber"
          key="portNumber"
          rules={[{ required: true, message: t('fillsocketkey').toString() }]}
        >
          <InputNumber min={1} max={GetMaxInut()} />
        </Form.Item>
        <Form.Item
          label={t('enabled')}
          name="enabled"
          key="enabled"
          valuePropName="checked"
          rules={[{ required: true, message: t('fillsocketkey').toString() }]}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t('writelogonchange')}
          name="writeLogOnChange"
          valuePropName="checked"
          rules={[{ required: true, message: t('fillsocketkey').toString() }]}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t('styleDefault')}
          name="styleDefault"
          key="styleDefault"
          rules={[{ required: true, message: t('fillsocketkey').toString() }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('styleInAction')}
          name="styleInAction"
          key="styleInAction"
          rules={[{ required: true, message: t('fillsocketkey').toString() }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
