import { Button } from 'antd';
import { useEffect, useState } from 'react';

import { PlusCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import style from './StripeTransputs.module.css';
import { Unit } from '../../../Models/PlatformManager/Unit';
import RenderCard from '../RenderCard';
import { serviceBundler } from '../../../App';

import { EspModule } from '../../../Models/PlatformManager/EspModule';
import { AddEspModuleForm } from '../../../Pages/PlatformManager/forms/AddEspModuleForm/AddEspModuleForm';
import { Transput } from '../../../Models/PlatformManager/Transput';
import { AddTransputForm } from '../../../Pages/PlatformManager/forms/AddTransputForm/AddTransputForm';
import { ModuleType } from '../../../Enums/ModuleType';

const StripeTransputs = (props: {
  moduleType: ModuleType;
  moduleId: string;
  transputs?: Transput[];
}) => {
  const [transput, setTransput] = useState<Transput>();
  const [transputs, setTransputs] = useState<Transput[]>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setTransputs(props.transputs);
  }, [props]);

  const FilterTransputs = (items: Transput[]): Transput[] => {
    return items.filter((x) => x.idModule == props.moduleId);
  };

  const AddUnit = () => {};

  const EditUnit = () => {};

  const NavContentTransput = (item: Transput) => {
    setTransput(item);
  };

  const IsItemActive = (item: Transput) => {
    if (transput?.name == item.name) {
      return true;
    }
    return false;
  };

  const handleCancelForm = () => {
    setIsModalOpen(false);
  };

  const OpenModelAddTransput = () => {
    var newTransput = new Transput();
    newTransput.moduleType = props.moduleType
    setTransput(newTransput);
    setModelEditMode(false);
    setIsModalOpen(true);
  };

  const OpenModelEditTransput = (item: Transput) => {
    setTransput(item);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const SafeTransput = async (item: Transput) => {
    setIsModalOpen(false);

    var result: Transput[] = new Array<Transput>();
    if (item.id !== undefined) {
      result = await serviceBundler.platformService.UpdateTransput(item);
    } else {
      if (serviceBundler.platformService.transputs == null) {
        serviceBundler.platformService.transputs = [item];
      } else {
        result = await serviceBundler.platformService.AddTransput(
          props.moduleId,
          item,
        );
      }
    }

    setTransputs(FilterTransputs(result));
  };

  const RemoveTransput = async (item: Transput) => {
    setIsModalOpen(false);

    setTransput(undefined);
    setTransputs(
      FilterTransputs(
        await serviceBundler.platformService.DeleteTransput(
          props.moduleId,
          item,
        ),
      ),
    );
  };

  return (
    <>
      <div id="style-1" className={style.scrollFor}>
        {transputs?.map((item: Transput, i) =>
          RenderCard(
            false,
            item,
            item.id,
            item.name,
            IsItemActive(item),
            NavContentTransput,
            OpenModelEditTransput,
          ),
        )}
        <AddTransputForm
          allData={transputs}
          data={transput}
          open={isModalOpen}
          onEdit={isModelEditMode}
          onCancel={handleCancelForm}
          onSafe={SafeTransput}
          onRemove={RemoveTransput}
        ></AddTransputForm>
        <Button
          onClick={OpenModelAddTransput}
          icon={<PlusCircleOutlined />}
          className={style.btnPos}
        >
          {t('addtransput')}
        </Button>
      </div>
    </>
  );
};

export default StripeTransputs;
