import { LocalHelper } from '../Helpers/LocalHelper';
import { fakeAuthProvider } from '../auth';

export class AuthService{
    private currentEmail!: string | null;
    private token: string = ""

    public SafeToken(token: string, expiresIn: string){
        LocalHelper.SafeValueWithKey("access_token", token)
        LocalHelper.SafeValueWithKey("expires_at", expiresIn)
        this.token = token
    }

    public GetToken(): string{
        var tempToken = LocalHelper.GetValueByKey("access_token");
        if (tempToken){
            return tempToken;
        }
        return "";
    }

    public IsLoggedIn(): boolean{
       return fakeAuthProvider.isAuthenticated;
    }

    public SetLoggedIn(){
        fakeAuthProvider.isAuthenticated = true;
     }

    public Logout(){
        fakeAuthProvider.isAuthenticated = false;
        LocalHelper.RemoveKey("access_token");
        LocalHelper.RemoveKey("expires_at");
        this.SetCurrentEmail(null)
    }

    public GetCurrentEmail(){
        return this.currentEmail;
    }

    public SetCurrentEmail(email: string | null){
        this.currentEmail = email;
    }
}