import { BehaviorSubject, Observable } from 'rxjs';
import { UserModel } from '../Models/Auth/UserModel';
import { authService, serviceBundler } from '../App';
import { AxiosResponse } from 'axios';
import { UserHelper } from '../Helpers/UserHelper';

export class UserService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public FetchingReady!: boolean;

  public users!: Array<UserModel>;
  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);
    this.GetFetchStatus();
  }

  private async FetchItems() {
    var token = authService.GetToken();

    if (!this.FetchingReady) {
      this.FetchingReady = true;
      this.users = await UserHelper.GetUsers(token);
      this.FetchingReadySubject.next(true);
    }
  }

  public GetFetchStatus(): Observable<boolean> {
    this.FetchItems();
    return this.FetchingReadySubject.asObservable();
  }

  // get overview
  public async GetUsers() {
    if (this.users == undefined) {
      await this.FetchItems();
    }
    return this.users;
  }

  public async GetUser(id: string): Promise<UserModel> {
    var index = (await this.GetUsers()).findIndex((x) => x.id === id);
    return this.users[index];
  }

  public async GetUserByEmail(email: string): Promise<UserModel> {
    var users = await this.GetUsers();
    if (users) {
      var index = users.findIndex((x) => x.email === email);
      return this.users[index];
    }
    return users;
  }
}
