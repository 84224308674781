import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button, Form, Input, InputNumber, Table, Typography } from 'antd';
import { TranslationLabelKey } from '../../../Models/Language/TranslationLabelKey';
import { useTranslation } from 'react-i18next';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { PlusCircleOutlined } from '@ant-design/icons';

import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined } from '@ant-design/icons';
import { serviceBundler } from '../../../App';

interface Item {
  key: string;
  languageKey: string;
  languageLabel: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  dataSource: TranslationLabelKey[];
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}

const checkLabelExist = (alldata: any, value: string) => {
  var currentLanguage =
    serviceBundler.languageService.GetCurrentLanguage().keysLabels;
  var foundItem = currentLanguage?.find((x) => x.languageKey === value);
  // if (foundItem !== undefined && alldata.field !== 'languageLabel') {
  //   return Promise.reject(new Error('Dit label bestaat al'));
  // }
  return Promise.resolve();
};

type DataIndex = keyof Item;

const EditableCell: React.FC<EditableCellProps> = ({
  dataSource,
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Vul het veld in: ${title}`,
            },
            { validator: checkLabelExist },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

export const TableTranslations = forwardRef(
  (
    props: {
      dataSource?: TranslationLabelKey[];
      safeData: Function;
    },
    ref,
  ) => {
    const { i18n, t } = useTranslation();

    const [form] = Form.useForm();
    const [data, setData] = useState<Array<Item>>([]);
    const [dataTemp, setTempData] = useState<Array<Item>>([]);
    const [editingKey, setEditingKey] = useState('');
    const [count, setCount] = useState(data.length);

    const isEditing = (record: Item) => record.key === editingKey;

    useImperativeHandle(ref, () => ({
      SetSearchText(text: string) {
        if (text.length == 0) {
          setData([...dataTemp]);
          return;
        }

        var filteredData = dataTemp.filter((x) => {
          return (
            x.languageKey.toLocaleLowerCase().includes(text) ||
            x.languageLabel.toLocaleLowerCase().includes(text)
          );
        });
        setData(filteredData);
      },
    }));

    useEffect(() => {
      if (props.dataSource != undefined) {
        setData(InitData(props.dataSource));
        setTempData(InitData(props.dataSource));
      }
    }, [props.dataSource]);

    const InitData = (items: TranslationLabelKey[]) => {
      var newItems: Item[] = [];
      for (var i = 0; i < items.length; i++) {
        newItems.push({
          key: i.toString(),
          languageKey: items[i].languageKey,
          languageLabel: items[i].languageLabel,
        });
      }
      return newItems;
    };

    const edit = (record: Partial<Item> & { key: React.Key }) => {
      form.setFieldsValue({ ...record });
      setEditingKey(record.key);
    };

    const handleDelete = (key: React.Key) => {
      const newData = dataTemp.filter((item) => item.key !== key);
      setData(newData);
      setTempData(newData);
      props.safeData(newData);
    };

    const cancel = () => {
      setEditingKey('');
    };

    const handleAdd = () => {
      setEditingKey('');

      const newData: Item = {
        key: (Math.random() + 1).toString(36).substring(7),
        languageKey: `x`,
        languageLabel: `x`,
      };
      setEditingKey(newData.key);
      setData([...data, newData]);
      setCount(count + 1);
    };

    const save = async (key: React.Key) => {
      try {
        const row = (await form.validateFields()) as Item;

        const newData = [...data];
        const index = newData.findIndex((item) => key === item.key);
        if (index >= 0) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          setData(newData);
          setEditingKey('');
        } else {
          newData.push(row);
          setData(newData);
          setEditingKey('');
        }

        SafeAllData(key, row);
      } catch (errInfo) {
      }
    };

    const SafeAllData = (key: React.Key, newItem: Item) => {
      const newData = [...data];
      const index = newData.findIndex(
        (item) => newItem.languageKey === item.languageKey,
      );
      if (index >= 0) {
        dataTemp[Number(key)] = newItem;
      } else {
        dataTemp.push(newItem);
      }
      props.safeData(dataTemp);
    };

    const SafeAllDataAfterDrag = (indexFirst: number, indexSecond: number) => {
      var newData = [...data];
      var tmp = newData[indexSecond];
      newData[indexSecond] = newData[indexFirst];
      newData[indexFirst] = tmp;
      props.safeData(newData);
    };

    const columns = [
      {
        key: 'sort',
      },
      {
        title: t('languageKey'),
        dataIndex: 'languageKey',
        width: '40%',
        editable: true,
      },
      {
        title: t('languageLabel'),
        dataIndex: 'languageLabel',
        width: '35%',
        editable: true,
      },
      {
        title: t('operation'),
        dataIndex: 'operation',
        width: '200px',
        editable: false,
        render: (_: any, record: Item) => {
          const editable = isEditing(record);
          return editable ? (
            <span style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{ marginRight: 8 }}
              >
                {t('safe')}
              </Typography.Link>
              <Typography.Link onClick={() => cancel()}>
                {t('cancel')}
              </Typography.Link>
            </span>
          ) : (
            <>
              <Typography.Link
                onClick={() => edit(record)}
                style={{ marginRight: 8 }}
              >
                {t('edit')}
              </Typography.Link>
              <Typography.Link onClick={() => handleDelete(record.key)}>
                {t('delete')}
              </Typography.Link>
            </>
          );
        },
      },
    ];

    const onDragEnd = ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        setData((previous) => {
          const activeIndex = previous.findIndex((i) => i.key === active.id);
          const overIndex = previous.findIndex((i) => i.key === over?.id);
          SafeAllDataAfterDrag(activeIndex, overIndex);

          return arrayMove(previous, activeIndex, overIndex);
        });
      }
    };

    const Row = ({ children, ...props }: RowProps) => {
      const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
      } = useSortable({
        id: props['data-row-key'],
      });

      const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(
          transform && { ...transform, scaleY: 1 },
        ),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
      };

      return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
          {React.Children.map(children, (child) => {
            if ((child as React.ReactElement).key === 'sort') {
              return React.cloneElement(child as React.ReactElement, {
                children: (
                  <MenuOutlined
                    ref={setActivatorNodeRef}
                    style={{ touchAction: 'none', cursor: 'move' }}
                    {...listeners}
                  />
                ),
              });
            }
            return child;
          })}
        </tr>
      );
    };

    const mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: Item) => ({
          record,
          inputType: col.dataIndex === 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

    return (
      <Form form={form} component={false}>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={data.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                body: {
                  row: Row,
                  cell: EditableCell,
                },
              }}
              size="small"
              dataSource={data}
              columns={mergedColumns}
              rowClassName="editable-row"
              scroll={{ y: 400, scrollToFirstRowOnChange: true }}

              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: (event) => {
              //       edit(record);
              //     },
              //   };
              // }}
            />
          </SortableContext>
        </DndContext>
        <Button
          icon={<PlusCircleOutlined />}
          disabled={editingKey == '' ? false : true}
          onClick={handleAdd}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          {t('addarow')}
        </Button>
      </Form>
    );
  },
);
