import { LogoSensorState } from './LogoSensorState';

export class LogosSensorStates {
  Type?: string;
  SessionId?: string;

  SensorStates: Array<LogoSensorState> = [];

  constructor() {}
}
