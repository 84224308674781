import React, { useEffect, useState } from 'react';
import { Affix, Button, Layout, Space, theme } from 'antd';

import style from './HomePage.module.css';

import { Footer, Header } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import { Platform } from '../../Models/PlatformManager/Platform';
import { authService, serviceBundler } from '../../App';
import {
  EditOutlined,
  ArrowRightOutlined,
  SettingOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Card } from 'antd';
import HeaderBar from '../../Components/HeaderBar/HeaderBar';
import { AddPlatform } from '../PlatformManager/forms/AddPlatformForm/AddPlatform';
import { useNavigate } from 'react-router-dom';
import { RequireAuth } from '../../Providers/AuthProvider';

const { Meta } = Card;
const { useToken } = theme;
const { Content } = Layout;

const Home = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { token } = useToken();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);

  const [initLading, setInitLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);

  const [platforms, setPlatforms] = useState<Platform[]>();
  const [platform, setPlatform] = useState<Platform>();
  const [addPlatform, setAddPlaform] = useState<Platform[]>();

  useEffect(() => {
    serviceBundler.platformService.GetFetchStatus().subscribe((value) => {
      if (!value) {
        return;
      }
      var items = serviceBundler.platformService.GetPlatforms();
      if (items !== undefined) {
        setPlatforms(items);
      }
    });
  }, [0]);

  const OpenModelAddPlatform = () => {
    setPlatform(new Platform());
    setModelEditMode(false);
    setIsModalOpen(true);
  };

  const OpenModelEditPlatform = (item: Platform) => {
    setPlatform(item);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const handleCancelForm = () => {
    setIsModalOpen(false);
  };

  const SafePlatform = async (item: Platform) => {
    setActionLoading(true);
    if (item.id !== undefined) {
      serviceBundler.platformService.UpdateBaseSpecificPlatform(item);
    } else {
      if (serviceBundler.platformService.platforms == null) {
        serviceBundler.platformService.platforms = [item];
      } else {
        await serviceBundler.platformService.AddPlatform(item);
      }
    }
    setActionLoading(false);
    setIsModalOpen(false);
  };

  const SafePlatformImage = async (url: string) => {
    var newPlatform = Object.assign(new Platform(), platform);
    newPlatform.linkToImage = url;
    setPlatform(newPlatform);

    var updatedPlatform =
      await serviceBundler.platformService.GetCurrentPlaform(newPlatform.id);
    serviceBundler.platformService.SetCurrentPlaform(updatedPlatform);
  };

  const RemovePlatform = async (props: Platform) => {
    await serviceBundler.platformService.DeletePlatform(props);
    setIsModalOpen(false);
  };

  const NavagateToSpecificPlatform = (item: Platform) => {
    navigate(`/${item.platformName}`, {
      state: {},
    });
  };

  const IsLoggedIn = (): boolean => {
    return authService.IsLoggedIn();
  };

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', minHeight: '100vh' }}
      size={[0, 48]}
    >
      <Layout style={{ width: '100%', minHeight: '100vh' }}>
        <HeaderBar></HeaderBar>

        <Content
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.contentBlock}
        >
          <div className={style.centerContent}>
            {platforms?.map((item: Platform, i) => (
              <Card
                style={{ width: 280 }}
                cover={
                  <img
                    alt="example"
                    src={item.linkToImage}
                    style={{ cursor: 'pointer' }}
                  />
                  // <iframe
                  //   id="iFrameExample"
                  //   title="iFrame Example"
                  //   src="http://localhost:3000/Potlijn"
                  // ></iframe>
                }
                actions={[
                  <ArrowRightOutlined
                    onClick={() => NavagateToSpecificPlatform(item)}
                    key="setting"
                  />,
                  <>
                    {/* <RequireAuth> */}
                    <EditOutlined
                      onClick={() => OpenModelEditPlatform(item)}
                      key="edit"
                    />
                  </>,
                ]}
              >
                <Meta
                  title={item.platformName}
                  description={
                    item.description ? (
                      item.description
                    ) : (
                      <>
                        <br></br>
                      </>
                    )
                  }
                />
              </Card>
            ))}
          </div>

          {isModalOpen && (
            <RequireAuth>
              <AddPlatform
                allData={platforms}
                data={platform}
                open={isModalOpen}
                onCancel={handleCancelForm}
                onSafe={SafePlatform}
                onSafeImage={SafePlatformImage}
                onRemove={RemovePlatform}
                onEdit={isModelEditMode}
                actionLoading={actionLoading}
              ></AddPlatform>
            </RequireAuth>
          )}
        </Content>

        <Footer
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.footerStyle}
        >
          <Affix offsetBottom={10}>
            <Button
              onClick={() => OpenModelAddPlatform()}
              icon={<PlusCircleOutlined />}
              className={style.addPlatform}
              type="primary"
            >
              {t('addplatform')}
            </Button>
          </Affix>
        </Footer>
      </Layout>
    </Space>
  );
};

export default Home;
