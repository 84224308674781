import { Button, Card } from 'antd';
import { useEffect, useState } from 'react';

import { PlusCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';

import style from './StripeTypesModules.module.css';
import { CurrentTypeModule } from '../../../Models/PlatformManager/CurrentTypeModule';

const { Meta } = Card;

interface TypeOfModule {
  nameTypeModule: string;
  typeModule: CurrentTypeModule;
}

const StripeTypesModules = (props: {
  currentType: CurrentTypeModule;
  chooseModuleType: Function;
}) => {
  const TypesModules: TypeOfModule[] = [
    {
      nameTypeModule: 'Style module',
      typeModule: CurrentTypeModule.StyleModule,
    },
    {
      nameTypeModule: 'Esp module',
      typeModule: CurrentTypeModule.EspModule,
    },
    {
      nameTypeModule: 'Logo module',
      typeModule: CurrentTypeModule.LogoModule,
    },
  ];

  const [currentType, setCurrentType] = useState<CurrentTypeModule>();

  useEffect(() => {
    setCurrentType(props.currentType);
  }, [props]);

  const NavigateToSpecifModule = (item: TypeOfModule) => {
    setCurrentType(item.typeModule);
    props.chooseModuleType(item.typeModule);
  };

  const IsActive = (item: TypeOfModule): boolean => {
    if (item.typeModule == currentType) {
      return true;
    }
    return false;
  };

  return (
    <>
      {TypesModules?.map((item: TypeOfModule, i) => (
        <Card
          size={'small'}
          style={{
            marginTop: '10px',
            backgroundColor: IsActive(item) ? '#C1D004' : '',
          }}
          key={item.typeModule}
          actions={[
            <ArrowRightOutlined
              onClick={() => NavigateToSpecifModule(item)}
              key="setting"
            />,
          ]}
        >
          <Meta title={item.nameTypeModule} />
        </Card>
      ))}
    </>
  );
};

export default StripeTypesModules;
