import { Button } from 'antd';
import { useEffect, useState } from 'react';

import { PlusCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import style from './StripeEspModules.module.css';
import { Unit } from '../../../Models/PlatformManager/Unit';
import RenderCard from '../RenderCard';
import { serviceBundler } from '../../../App';

import { EspModule } from '../../../Models/PlatformManager/EspModule';
import { AddEspModuleForm } from '../../../Pages/PlatformManager/forms/AddEspModuleForm/AddEspModuleForm';

const StripeEspModules = (props: {
  unit: Unit;
  setModule: Function;
  modules?: EspModule[];
}) => {
  const [espModule, setEspModule] = useState<EspModule>();
  const [espModules, setEspModules] = useState<Array<EspModule>>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setEspModules(props.modules);
  }, [props]);

  const FilterModules = (items: EspModule[]) => {
    return items.filter((x) => x.idUnit == props.unit.id);
  };

  const NavContentUnit = (item: EspModule) => {
    setEspModule(item);
    props.setModule(item.id);
  };

  const IsItemActive = (item: EspModule) => {
    if (espModule?.name == item.name) {
      return true;
    }
    return false;
  };

  const handleCancelForm = () => {
    setIsModalOpen(false);
  };

  const OpenModelAddUnit = () => {
    props.setModule(undefined);
    setEspModule(new EspModule());
    setModelEditMode(false);
    setIsModalOpen(true);
  };

  const OpenModelEditUnit = (item: EspModule) => {
    setEspModule(item);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const SafeEspModule = async (item: EspModule) => {
    setIsModalOpen(false);
    
    var result = Array<EspModule>();
    if (item.id !== undefined) {
      result = await serviceBundler.platformService.UpdateEspModule(item);
    } else {
      result = await serviceBundler.platformService.AddEspModule(
        props.unit,
        item,
      );
    }

    setEspModules(FilterModules(result));
  };

  const RemoveEspModule = async (item: EspModule) => {
    setIsModalOpen(false);
    setEspModule(undefined);
    setEspModules(
      FilterModules(
        await serviceBundler.platformService.DeleteEspModule(props.unit, item),
      ),
    );
  };

  return (
    <>
      <div className={style.scrollFor}>
        {espModules?.map((item: EspModule, i) =>
          RenderCard(
            true,
            item,
            item.id,
            item.name,
            IsItemActive(item),
            NavContentUnit,
            OpenModelEditUnit,
          ),
        )}
      </div>

      <AddEspModuleForm
        allData={espModules}
        data={espModule}
        open={isModalOpen}
        onEdit={isModelEditMode}
        onCancel={handleCancelForm}
        onSafe={SafeEspModule}
        onRemove={RemoveEspModule}
      ></AddEspModuleForm>
      <Button
        onClick={OpenModelAddUnit}
        icon={<PlusCircleOutlined />}
        className={style.btnPos}
      >
        {t('addespmodule')}
      </Button>
    </>
  );
};

export default StripeEspModules;
