import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ServiceBundler } from './Services/ServiceBundler';
import { AuthProvider, RequireAuth } from './Providers/AuthProvider';
import LoginPage from './Pages/Login/LoginPage';
import HomePage from './Pages/Home/HomePage';
import InfoPage from './Pages/Info/InfoPage';
import PlatformManager from './Pages/PlatformManager/platformmanager/PlatformManager';
import ConfigPlatform from './Pages/PlatformManager/configplatform/ConfigPlatform';
import TranslationsPage from './Pages/Languages/Translations/TranslationsPage';
import LanguagePage from './Pages/Languages/LanguagePage';
import ProfilePage from './Pages/Profile/ProfilePage';
import { ConfigProvider, ThemeConfig, theme } from 'antd';
import { AuthService } from './Services/AuthService';
import { WebSocketService } from './Services/WebSocketService';

export const webSocketService = new WebSocketService();
export const authService = new AuthService();
export const serviceBundler = new ServiceBundler();

export const configThemeLight: ThemeConfig = {
  token: {
    colorPrimary: '#C1D202',
    colorPrimaryText: '#00365f',
    colorPrimaryBg: '#FFFFFF',
  },
};

const configThemeDark: ThemeConfig = {
  token: {
    colorPrimary: '#C1D202',
    colorPrimaryText: '#C1D202',
    colorPrimaryBg: '#141414',
  },
};

export default function App() {
  const [currentTheme, setCurrentTheme] = React.useState<'light' | 'dark'>(
    'light',
  );

  React.useEffect(() => {
    setCurrentTheme(serviceBundler.themeService.IsDarkMode ? 'dark' : 'light');

    serviceBundler.themeService.FetchingReadySubject.subscribe((value) => {
      if (value) {
        setCurrentTheme('dark');
      } else {
        setCurrentTheme('light');
      }
    });
  }, [0]);

  return (
    <ConfigProvider
      theme={{
        token:
          currentTheme === 'light'
            ? configThemeLight.token
            : configThemeDark.token,
        algorithm:
          currentTheme === 'light'
            ? theme.defaultAlgorithm
            : theme.darkAlgorithm,
      }}
    >
      <AuthProvider>
        <Routes>
          <Route>
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<HomePage />} />
            <Route
              path="profile"
              element={
                <RequireAuth>
                  <ProfilePage />
                </RequireAuth>
              }
            />
            <Route path=":platformKey">
              <Route
                index
                element={
                  <RequireAuth>
                    <PlatformManager />
                  </RequireAuth>
                }
              />
              <Route
                path="config"
                element={
                  <RequireAuth>
                    <ConfigPlatform />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="languages">
              <Route
                index
                element={
                  <RequireAuth>
                    <LanguagePage />
                  </RequireAuth>
                }
              ></Route>

              <Route
                path=":languageKey"
                element={
                  <RequireAuth>
                    <TranslationsPage />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="info"
              element={
                <RequireAuth>
                  <InfoPage />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </AuthProvider>
    </ConfigProvider>
  );
}
