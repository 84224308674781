import { EspModule } from "../Models/PlatformManager/EspModule"
import { LogoModule } from "../Models/PlatformManager/LogoModule"
import { Platform } from "../Models/PlatformManager/Platform"
import { StylingModule } from "../Models/PlatformManager/StylingModule"
import { Transput } from "../Models/PlatformManager/Transput"
import { Unit } from "../Models/PlatformManager/Unit"
import { HttpHelper } from "./HttpHelper"

export abstract class PlatformHelper{
    private static baseUrl = HttpHelper.GetUrlBaseApi() + "api/" + "Platform"
    public static async GetPlatforms(): Promise<Platform[]> {
        var urlForRequest = this.baseUrl
        return (await HttpHelper.GetRequestForApi<Platform[]>(urlForRequest))
    }

    public static async GetSpecificPlaform(id: string): Promise<Platform> {
        var urlForRequest = this.baseUrl + "/" + id;
        return (await HttpHelper.GetRequestForApi<Platform>(urlForRequest))
    }

    public static async GetStylingModules(id: string): Promise<StylingModule[]> {
        var urlForRequest = this.baseUrl + "/stylingmodules/" + id;
        return (await HttpHelper.GetRequestForApi<StylingModule[]>(urlForRequest))
    }

    public static async GetEspModules(id: string): Promise<EspModule[]> {
        var urlForRequest = this.baseUrl + "/espmodules/" + id;
        return (await HttpHelper.GetRequestForApi<EspModule[]>(urlForRequest))
    }

    public static async GetLogoModules(id: string): Promise<LogoModule[]> {
        var urlForRequest = this.baseUrl + "/logomodules/" + id;
        return (await HttpHelper.GetRequestForApi<LogoModule[]>(urlForRequest))
    }

    public static async GetTransputModules(id: string): Promise<Transput[]> {
        var urlForRequest = this.baseUrl + "/transputs/" + id;
        return (await HttpHelper.GetRequestForApi<Transput[]>(urlForRequest))
    }

    public static async GetTransputs(platformName: string): Promise<Transput[]> {
        var urlForRequest = this.baseUrl + "/transputs/" + platformName;
        return (await HttpHelper.GetRequestForApi<Transput[]>(urlForRequest))
    }

    public static async UpdateBaseSpecificPlatform(item: Platform){
        var urlForRequest = this.baseUrl + "/platformBase/" + item.id
        return (await HttpHelper.UpdateRequestForApi<Platform>(urlForRequest, item))
    }

    public static async UpdateSpecificPlatform(item: Platform){
        var urlForRequest = this.baseUrl + "/platform/" + item.id
        return (await HttpHelper.UpdateRequestForApi<Platform>(urlForRequest, item))
    }

    public static async UpdateStylingModule(item: StylingModule){
        var urlForRequest = this.baseUrl + "/stylingmodule/" + item.id
        return (await HttpHelper.UpdateRequestForApi<StylingModule>(urlForRequest, item))
    }

    public static async UpdateEspModule(item: EspModule){
        var urlForRequest = this.baseUrl + "/espmodule/" + item.id
        return (await HttpHelper.UpdateRequestForApi<EspModule>(urlForRequest, item))
    }

    public static async UpdateLogoModule(item: LogoModule){
        var urlForRequest = this.baseUrl + "/logomodule/" + item.id
        return (await HttpHelper.UpdateRequestForApi<LogoModule>(urlForRequest, item))
    }

    public static async UpdateTransput(item: Transput){
        var urlForRequest = this.baseUrl + "/transput/" + item.id
        return (await HttpHelper.UpdateRequestForApi<Transput>(urlForRequest, item))
    }

    public static GetLinkAddImagePlatform(id : string): string{
        return this.baseUrl + "/"+ id + "/ImagePlatform";
    }

    public static async AddPlatform(item: Platform){
        var urlForRequest = this.baseUrl + "/Platform"
        return (await HttpHelper.PostRequestForApi<Platform>(urlForRequest, item))
    }

    public static async AddStylingModule(unit: Unit, item: StylingModule){
        var urlForRequest = this.baseUrl + "/" + unit.id +"/StylingModule"
        return (await HttpHelper.PostRequestForApi<StylingModule>(urlForRequest, item))
    }

    public static async AddEspModule(unit: Unit, item: EspModule){
        var urlForRequest = this.baseUrl + "/" + unit.id +"/EspModule"
        return (await HttpHelper.PostRequestForApi<EspModule>(urlForRequest, item))
    }

    public static async AddLogoModule(unit: Unit, item: LogoModule){
        var urlForRequest = this.baseUrl + "/" + unit.id +"/LogoModule"
        return (await HttpHelper.PostRequestForApi<LogoModule>(urlForRequest, item))
    }

    public static async AddTransput(moduleId: string, item: Transput){
        var urlForRequest = this.baseUrl + "/" + moduleId + "/Transput"
        return (await HttpHelper.PostRequestForApi<Transput>(urlForRequest, item))
    }

    public static async DeletePlatform(setting: Platform){
        var urlForRequest = this.baseUrl + "/" + setting.id
        return (await HttpHelper.DeleteRequestForApi<Platform>(urlForRequest))
    }

    public static async DeleteStylingModule(unit: Unit, item: StylingModule){
        var urlForRequest = this.baseUrl + "/" + unit.id + "/StylingModule/" + item.id
        return (await HttpHelper.DeleteRequestForApi<Platform>(urlForRequest))
    }

    public static async DeleteEspModule(unit: Unit, item: EspModule){
        var urlForRequest = this.baseUrl + "/" + unit.id + "/EspModule/" + item.id
        return (await HttpHelper.DeleteRequestForApi<EspModule>(urlForRequest))
    }

    public static async DeleteLogoModule(unit: Unit, item: LogoModule){
        var urlForRequest = this.baseUrl + "/" + unit.id + "/LogoModule/" + item.id
        return (await HttpHelper.DeleteRequestForApi<LogoModule>(urlForRequest))
    }

    public static async DeleteTransput(moduleId: string, item: Transput){
        var urlForRequest = this.baseUrl + "/" + moduleId + "/Transput/" + item.id
        return (await HttpHelper.DeleteRequestForApi<Transput>(urlForRequest))
    }
}