import { ModuleType } from "../../Enums/ModuleType";
import { TransputType } from "../../Enums/TransputType";

export class Transput{
    id!: string

    idPlatform!: string
    idModule!: string

    name!: string
    sensorId!: string;
    description!: string
    transputType!: TransputType
    moduleType!: ModuleType;
    portNumber: number = 1
    portValue? : boolean = false;
    enabled: boolean = false;
    writeLogOnChange: boolean = false;
    styleDefault: string = ""
    styleInAction: string = ""
  }