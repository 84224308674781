import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Platform } from '../../../Models/PlatformManager/Platform';
import { Transput } from '../../../Models/PlatformManager/Transput';
import { Space, Layout, Button, Divider, theme } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';

import { ArrowLeftOutlined } from '@ant-design/icons';

import style from './ConfigPlatform.module.css';
import { useTranslation } from 'react-i18next';
import { Unit } from '../../../Models/PlatformManager/Unit';
import { serviceBundler } from '../../../App';
import ConfigUnit from '../../../Components/PlatformManager/StripeConfigUnit/StripeConfigUnit';
import TypesModules from '../../../Components/PlatformManager/StripeTypesModules/StripeTypesModules';
import { CurrentTypeModule } from '../../../Models/PlatformManager/CurrentTypeModule';
import { StylingModule } from '../../../Models/PlatformManager/StylingModule';
import { EspModule } from '../../../Models/PlatformManager/EspModule';
import StripeEspModules from '../../../Components/PlatformManager/StripeEspModules/StripeEspModules';
import StripeStyleModules from '../../../Components/PlatformManager/StripeStyleModules/StripeStyleModules';
import { LogoModule } from '../../../Models/PlatformManager/LogoModule';
import StripeTransputs from '../../../Components/PlatformManager/StripeTransputs/StripeTransputs';
import StripeLogoModules from '../../../Components/PlatformManager/StripeLogoModules/StripeLogoModules';
import { ModuleType } from '../../../Enums/ModuleType';

const { useToken } = theme;

const ConfigPlatform = () => {
  const { platformKey } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { token } = useToken();

  const [platform, setPlatform] = useState<Platform>(new Platform());
  const [unit, setUnit] = useState<Unit>();

  const [stylingModules, setStylingModules] = useState<StylingModule[]>();
  const [espModules, setEspModules] = useState<EspModule[]>();
  const [logoModules, setLogoModules] = useState<LogoModule[]>();

  const [moduleId, setModuleId] = useState<string>();
  const [transputs, setTransputs] = useState<Transput[]>();

  const [currentModule, setCurrentModule] = useState<CurrentTypeModule>(
    CurrentTypeModule.NoneModule,
  );

  const [currentModuleType, setCurrentModuleType] = useState<ModuleType>(
    ModuleType.EspTransput,
  );

  const refTypesModules = useRef<null | HTMLDivElement>(null);
  const refModules = useRef<null | HTMLDivElement>(null);
  const refTransputs = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (platformKey) {
      FetchData(platformKey);
    }
  }, [0]);

  const FetchData = async (platformKey: string) => {
    var fetchedPlatform =
      await serviceBundler.platformService.GetSpecificPlaform(platformKey);
    var fetchedStylingModules =
      await serviceBundler.platformService.GetStylingModules();
    var fetchedEspModules =
      await serviceBundler.platformService.GetEspModules();
    var fetchedLogoModules =
      await serviceBundler.platformService.GetLogoModules();
    var fetchedTransputs = await serviceBundler.platformService.GetTransputs();

    if (fetchedPlatform === undefined) {
      navigate('/');
    }

    setPlatform(fetchedPlatform);
    setStylingModules(fetchedStylingModules);
    setEspModules(fetchedEspModules);
    setLogoModules(fetchedLogoModules);
    setTransputs(fetchedTransputs);
  };

  const BackToPlatform = () => {
    navigate(`/${platformKey}`, {
      state: {},
    });
  };

  const EditUnit = () => {};

  const ChooseUnit = (item: Unit) => {
    setUnit(item);
    setCurrentModule(CurrentTypeModule.NoneModule);
    refTypesModules!.current!.scrollIntoView();
  };

  const ChooseModuleType = (item: CurrentTypeModule) => {
    setCurrentModule(item);
    setModuleId(undefined);
    refModules!.current!.scrollIntoView();
  };

  const ChooseModuleEsp = (moduleId: string) => {
    setModuleId(moduleId);
    setCurrentModuleType(ModuleType.EspTransput);
    refTransputs!.current!.scrollIntoView();
  };

  const ChooseModuleLogo = (moduleId: string) => {
    setModuleId(moduleId);
    setCurrentModuleType(ModuleType.LogoTransout);
    refTransputs!.current!.scrollIntoView();
  };

  const GetModuleId = () => {
    if (moduleId) {
      return moduleId;
    }
    return '';
  };

  const RenderStylingModules = () => {
    var filteredStylingModules = stylingModules?.filter(
      (x) => x.idUnit == unit?.id,
    );

    if (unit && currentModule == CurrentTypeModule.StyleModule) {
      return (
        <StripeStyleModules
          unit={unit}
          modules={filteredStylingModules}
        ></StripeStyleModules>
      );
    }
    return <></>;
  };

  const RenderEspModules = () => {
    var filteredEspModules = espModules?.filter((x) => x.idUnit == unit?.id);

    if (unit && currentModule == CurrentTypeModule.EspModule) {
      return (
        <StripeEspModules
          unit={unit}
          setModule={ChooseModuleEsp}
          modules={filteredEspModules}
        ></StripeEspModules>
      );
    }
    return <></>;
  };

  const RenderLogoModules = () => {
    var filteredLogoModules = logoModules?.filter((x) => x.idUnit == unit?.id);

    if (unit && currentModule == CurrentTypeModule.LogoModule) {
      return (
        <StripeLogoModules
          unit={unit}
          setModule={ChooseModuleLogo}
          modules={filteredLogoModules}
        ></StripeLogoModules>
      );
    }
    return <></>;
  };

  const RenderTranputs = () => {
    var filteredTransputs = transputs?.filter((x) => x.idModule == moduleId);

    if (
      (currentModule == CurrentTypeModule.EspModule ||
        currentModule == CurrentTypeModule.LogoModule) &&
      moduleId != undefined
    ) {
      return (
        <StripeTransputs
          moduleType={currentModuleType}
          moduleId={GetModuleId()}
          transputs={filteredTransputs}
        ></StripeTransputs>
      );
    }
    return <></>;
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <Header
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.headerStyle}
        >
          <div>
            <Button
              onClick={BackToPlatform}
              icon={<ArrowLeftOutlined />}
              className={style.backBtn}
            ></Button>
            <div className={style.textName}>{platformKey}</div>
          </div>
        </Header>

        <Content
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.contentBlock}
        >
          <div className={style.centerContent}>
            <div className={style.columnBlock}>
              <Divider>Units</Divider>
              <div className={style.contentBlock}>
                <ConfigUnit
                  platform={platform}
                  units={platform.units}
                  chooseUnit={ChooseUnit}
                ></ConfigUnit>
              </div>
            </div>
            <div ref={refTypesModules}>
              <div className={style.columnBlock}>
                <Divider>Soorten modules</Divider>
                <div className={style.contentBlock}>
                  {unit && (
                    <TypesModules
                      currentType={currentModule}
                      chooseModuleType={ChooseModuleType}
                    ></TypesModules>
                  )}
                </div>
              </div>
            </div>
            <div ref={refModules}>
              <div className={style.columnBlock}>
                <Divider>Modules</Divider>
                <div className={style.contentBlock}>
                  {RenderStylingModules()}
                  {RenderEspModules()}
                  {RenderLogoModules()}
                </div>
              </div>
            </div>
            <div ref={refTransputs}>
              <div className={style.columnBlock}>
                <Divider>Transputs</Divider>
                <div className={style.contentBlock}>{RenderTranputs()}</div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Space>
  );
};

export default ConfigPlatform;
